import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

export default function UnstyledButton({ children, onClick }: Props) {
  return (
    <button
      style={{
        padding: 0,
        border: 0,
        paddingBlock: 0,
        paddingInline: 0,
        cursor: 'pointer',
        backgroundColor: 'transparent',
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
