import {
  COLORS,
  LBTButton,
  LBTButtonIllustrative,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  categoriesState,
  getCategoriesHomePageCallback,
  useBreakpoint,
} from '@laborability/commons';
import { Grid } from '@mui/material';
import { useRecoilCallback, useRecoilValue } from 'recoil';

export default function ConcessionsQuestionaries() {
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();

  const categories = useRecoilValue(categoriesState);
  const getAllCategories = useRecoilCallback(getCategoriesHomePageCallback, []);

  useEffect(() => {
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Sblocca le agevolazioni che ti spettano rispondendo alle domande del
        Questionario
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText2" component="p">
        Alla fine tornerai qui e vedrai incentivi, sgravi e bonus divisi per
        categoria. Per sbloccarli tutti, entra nelle categorie che ti
        interessano e rispondi a qualche altra domanda
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTButton
        backgroundColor={COLORS.getInstance().PRIMARY_MAIN}
        onClick={() => {}}
        size="large"
        sx={{ width: '100%', maxWidth: '358px' }}
        variant="contained"
      >
        Inizia il Questionario
      </LBTButton>
      <LBTSpacer spacing={4} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
      >
        {categories.map((category, index) => (
          <Grid item key={index} mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={
                'home_button_cat_' +
                category.name
                  ?.split(' ')
                  .join('-')
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()
              }
              onClick={() => {}}
              icon={category.icon as React.ReactNode}
              label={category.name as string}
              subLabel="Rispondi a qualche domanda"
              iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
              locked
            />
          </Grid>
        ))}
      </Grid>
      <LBTSpacer spacing={6} />
    </Section>
  );
}
