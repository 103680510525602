import { LBTLabel, LBTSpacer, Section } from '@laborability/components';
import useExternalScript from '../../hooks/useExternalScript';

export default function CookiePolicy() {
  useExternalScript(
    'https://consent.cookiebot.com/84fafea8-846c-42ef-bbce-9f497617d80f/cd.js',
  );

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Cookie policy
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <div id="CookieDeclaration"></div>
      <LBTSpacer spacing={8} isFixed />
    </Section>
  );
}
