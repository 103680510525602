import { COLORS } from '../../utils';

const ImageYoutubeComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.67546 0.752117C8.39146 0.597831 10.1743 0.462402 12 0.462402C13.8257 0.462402 15.6086 0.596117 17.3229 0.753831C20.1823 1.01097 22.5618 3.25497 22.8978 6.12983C23.0383 7.31612 23.1429 8.54354 23.1429 9.80183C23.1429 11.0601 23.0366 12.2875 22.8978 13.4738C22.5618 16.3487 20.1823 18.591 17.3229 18.8515C15.6086 19.0058 13.8257 19.1413 12 19.1413C10.1743 19.1413 8.39146 19.0075 6.67546 18.8498C3.81775 18.5944 1.44003 16.3504 1.10061 13.4755C0.948012 12.2568 0.866731 11.0301 0.857178 9.80183C0.857178 8.54354 0.963463 7.31612 1.10061 6.12983C1.43832 3.25497 3.81775 1.01269 6.67546 0.752117ZM16.5155 9.80183C16.5155 11.0773 13.1743 13.0675 10.776 14.3327C9.67032 14.9155 8.38461 14.0978 8.38461 12.8481V6.75555C8.38461 5.50412 9.67032 4.68812 10.776 5.27097C13.1743 6.53612 16.5155 8.52812 16.5155 9.80183Z"
        fill={COLORS.getInstance().PRIMARY_MAIN}
      />
    </svg>
  );
};

export default ImageYoutubeComponent;
