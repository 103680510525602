import {
  COLORS,
  ImageFamily,
  ImageKeyDoor,
  ImageManAndMagnifyingGlass,
  ImagePuzzle,
  ImageTurtleAndBunny,
  LBTButton,
  LBTLabel,
  LBTPagination,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '@laborability/commons';
import { Grid } from '@mui/material';
import { APP_ROUTES } from '../../Routes';

const ITEMS = [
  {
    image: <ImageManAndMagnifyingGlass />,
    title: '1. Profilo personale',
    subtitle: 'domande semplici per iniziare e rompere il ghiaccio',
    color: COLORS.getInstance().TANGERINE_MAIN,
  },
  {
    image: <ImageFamily />,
    title: '2. I tuoi cari',
    subtitle:
      'perché ci teniamo a mostrarti anche i bonus per chi ti sta a cuore',
    color: COLORS.getInstance().BERRY_POPSICLE_MAIN,
  },
  {
    image: <ImageKeyDoor />,
    title: '3. Super domande',
    subtitle: 'domande difficili ma cruciali per valutare tantissimi bonus',
    color: COLORS.getInstance().MINT_TONIC_MAIN,
  },
  {
    image: <ImagePuzzle />,
    title: '4. Le categorie',
    subtitle:
      'domande specifiche per sbloccare i bonus che ti interessano, divisi per area tematica',
    color: COLORS.getInstance().LEMON_CAKE_MAIN,
  },
];

export default function OnboardingQuestionaries() {
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  const [currentPage, setCurrentPage] = useState(0);

  const renderItem = (item: (typeof ITEMS)[number]) => {
    return (
      <Grid item mobile={12} desktop={6} display="flex">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            border: `1px solid ${item.color}`,
            borderRadius: '12px',
            backgroundColor: `${item.color}26`,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {item.image}
          <LBTLabel variant="bodyText" style={{ fontWeight: 700 }}>
            {item.title}
          </LBTLabel>
          <LBTLabel variant="bodyText">{item.subtitle}</LBTLabel>
        </div>
      </Grid>
    );
  };

  if (currentPage === 0)
    return (
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Semplifichiamo la complessità
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Là fuori c’è una giungla di bonus, incentivi, agevolazioni, sgravi
          fiscali. Capirci qualcosa è un’impresa, ma la fatica la facciamo noi
          per te
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <ImageTurtleAndBunny />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="bodyText1">
          Rispondi alle domande del Questionario e sblocca i tuoi bonus: solo
          quelli che ti spettano, senza rumore di fondo
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <LBTButton
          onClick={() => {
            setCurrentPage(1);
          }}
          variant="contained"
          sx={{ width: '100%', maxWidth: '358px' }}
        >
          Avanti
        </LBTButton>
      </Section>
    );

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Sblocca i tuoi bonus con il Questionario
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Abbiamo preparato una serie di domande. Le tue risposte ci permettono di
        valutare centinaia di bonus e a selezionare solo quelli giusti per te
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        sx={{
          maxWidth: isDesktop ? '1032px' : undefined,
        }}
      >
        {isDesktop
          ? ITEMS.map(item => renderItem(item))
          : ITEMS && renderItem(ITEMS[currentPage - 1])}
      </Grid>
      {!isDesktop && (
        <>
          <LBTSpacer spacing={2} />
          <LBTPagination
            numberOfItems={ITEMS.length}
            onPageChange={tmpPage => setCurrentPage(tmpPage)}
            currentPage={currentPage}
          />
        </>
      )}
      <LBTSpacer spacing={4} />
      {currentPage === 4 || isDesktop ? (
        <LBTButton
          onClick={() => {
            navigate(`/${APP_ROUTES.QUESTIONARIES_CONCESSIONS}`);
          }}
          variant="contained"
          sx={{ width: '100%', maxWidth: '358px' }}
        >
          Avanti
        </LBTButton>
      ) : (
        <LBTButton
          onClick={() => {
            navigate(`/${APP_ROUTES.QUESTIONARIES_CONCESSIONS}`);
          }}
          variant="invisible"
          sx={{ width: '100%', maxWidth: '358px' }}
        >
          Salta l'introduzione
        </LBTButton>
      )}
      <LBTSpacer spacing={6} />
    </Section>
  );
}
