import { COLORS } from '../../utils';

const ImageFacebookComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_2762_13912)">
        <path
          d="M15.6097 24.0243C15.6132 23.9913 15.6152 23.9573 15.6152 23.9233V15.8218H18.3847C18.8287 15.8218 19.2282 15.5293 19.2932 15.0898C19.3805 14.4737 19.378 13.8481 19.2857 13.2328C19.2217 12.8013 18.8377 12.5178 18.4017 12.5178H15.6152C15.6152 10.0208 16.0307 9.65776 18.3727 9.61176C18.8222 9.60326 19.2252 9.30676 19.2907 8.86176C19.4007 8.11426 19.3567 7.46126 19.2877 7.00176C19.2242 6.57726 18.8437 6.30076 18.4147 6.30426C14.2752 6.34026 11.9177 6.82976 11.9177 12.5173H9.77368C9.35668 12.5173 8.98668 12.7788 8.92368 13.1903C8.85568 13.6343 8.81468 14.2778 8.92818 15.0603C8.99318 15.5123 9.40018 15.8218 9.85718 15.8218H11.9177V24.0488C8.71268 24.0278 6.41868 23.9013 4.92518 23.7798C3.28118 23.6463 2.01268 22.3778 1.87918 20.7338C1.74618 19.0983 1.60718 16.5023 1.60718 12.8018C1.60718 9.10126 1.74618 6.50526 1.87918 4.86976C2.01268 3.22576 3.28118 1.95726 4.92518 1.82376C6.56068 1.69076 9.15668 1.55176 12.8572 1.55176C16.5577 1.55176 19.1537 1.69076 20.7892 1.82376C22.4332 1.95726 23.7017 3.22576 23.8352 4.86976C23.9682 6.50526 24.1072 9.10126 24.1072 12.8018C24.1072 16.5023 23.9682 19.0983 23.8352 20.7338C23.7017 22.3778 22.4332 23.6463 20.7892 23.7798C19.5942 23.8768 17.8867 23.9768 15.6097 24.0243Z"
          fill={COLORS.getInstance().PRIMARY_MAIN}
        />
      </g>
      <defs>
        <clipPath id="clip0_2762_13912">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.857178 0.801758)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImageFacebookComponent;
