import { Misura } from '@laborability/commons';
import {
  COLORS,
  IconMoneyFaceComponent,
  IconSofaComponent,
  IconsSize,
  IconsStyle,
  LBTAlert,
} from '@laborability/components';
import { Box } from '@mui/material';

const AlertsList: React.FC<{ misura?: Misura; isDesktop: boolean }> = ({
  misura,
  isDesktop,
}) => {
  return (
    <Box>
      {misura?.for_all_incomes ? (
        <LBTAlert
          icon={
            <IconMoneyFaceComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().PRIMARY_SUPERDARK}
            />
          }
          title="Per tutti i redditi"
          severity="info"
          variant="negative"
          message="Ottieni questo bonus senza presentare l'ISEE"
          datatestid="alert-1"
          sx={{ order: isDesktop ? 3 : 1 }}
        />
      ) : (
        <></>
      )}
      {misura?.question_online_or_phone ? (
        <LBTAlert
          icon={
            <IconSofaComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().PRIMARY_SUPERDARK}
            />
          }
          title="Domanda online o al telefono"
          severity="info"
          variant="negative"
          message="Ottieni questo bonus senza dover andare allo sportello"
          datatestid="alert-1"
          sx={{ order: isDesktop ? 4 : 2, marginTop: '16px' }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AlertsList;
