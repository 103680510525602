import {
  COLORS,
  Image404,
  LBTButton,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import useTrackPage from '../hooks/useTrackPage';
import { useBreakpoint } from '@laborability/commons';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../Routes';

const NoPage404 = () => {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  useTrackPage('Pagina non trovata', '/app/error-404');

  return (
    <Section
      backgroundColor={COLORS.getInstance().WHITE}
      style={{ display: 'flex', flex: 1 }}
    >
      <LBTSpacer spacing={8} isFixed />
      <LBTLabel variant="delaDisplay" component="h1">
        Puf! Pagina svanita
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Hai raggiunto una pagina che non è più online. Potremmo averla
        cancellata oppure averle cambiato posizione
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <Image404 width={isDesktop ? 300 : 200} height={isDesktop ? 300 : 200} />
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText">
        Gli errori 404 sono così: arrivano quando meno te lo aspetti e non si
        sanno spiegare troppo bene. Nel dubbio:
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTButton onClick={() => navigate(APP_ROUTES.HOME)} variant="contained">
        Torna alla home
      </LBTButton>
      <LBTSpacer spacing={4} />
    </Section>
  );
};

export default NoPage404;
