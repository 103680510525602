const IconLockComponent = ({ color }: { color: string }) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9.77366V10.8937M9.50017 6.63527V4.26682C9.50017 3.40041 9.13138 2.56948 8.47498 1.95684C7.8186 1.34418 6.92834 1 6.00006 1C5.07178 1 4.18152 1.34418 3.52513 1.95684C2.86875 2.56948 2.49999 3.40041 2.49999 4.26682V6.63527M5.99999 15C9.19998 15 11 13.32 11 10.3332C11 7.34649 9.19998 5.66644 5.99999 5.66644C2.8 5.66644 1 7.34649 1 10.3332C1 13.32 2.8 15 5.99999 15Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconLockComponent;
