import {
  currentCompanyConfigState,
  useBreakpoint,
} from '@laborability/commons';
import {
  COLORS,
  ImageLogoComponent,
  IconsSize,
  IconsStyle,
  IconSearchComponent,
  IconHomeComponent,
  LBTButton,
  LBTButtonIcon,
} from '@laborability/components';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APP_ROUTES } from '../../Routes';
import { useRecoilValue } from 'recoil';

const StyledStickyHeader = styled(Stack)<{
  backgroundColor: string;
  height: string;
}>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid ${COLORS.getInstance().BLACK};
  ${props => `height: ${props.height};`}
  transition: all 0.5s linear;
  left: 0;
`;
export type LBTNavMenuProps = {
  backgroundColor?: string;
};

const NavMenu: React.FC<LBTNavMenuProps> = ({
  backgroundColor = COLORS.getInstance().WHITE,
}: LBTNavMenuProps) => {
  const { logo } = useRecoilValue(currentCompanyConfigState);
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();

  const onHomeClick = () => navigate(APP_ROUTES.HOME);

  return (
    <StyledStickyHeader
      height={isDesktop ? '100px' : '64px'}
      backgroundColor={backgroundColor}
      direction="column"
    >
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        height="100%"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" marginLeft={'16px'}>
          <LBTButton datatestid="link_topbar_logo" onClick={onHomeClick}>
            {logo ? (
              <img
                src={logo}
                style={{
                  maxHeight: isDesktop ? '80px' : '44px',
                  maxWidth: isDesktop ? '182px' : '122px',
                }}
              />
            ) : (
              <ImageLogoComponent
                height={isDesktop ? '24px' : '16px'}
                width={isDesktop ? '182px' : '122px'}
              />
            )}
          </LBTButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginRight={'16px'}
        >
          <LBTButtonIcon
            datatestid="link_topbar_search"
            onClick={() => {
              navigate(`${APP_ROUTES.SEARCH_MISURE}`);
            }}
          >
            <IconSearchComponent
              datatestid="link_topbar_search"
              size={IconsSize.LARGE}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BLACK}
            />
          </LBTButtonIcon>
          <LBTButtonIcon datatestid="link_topbar_home" onClick={onHomeClick}>
            <IconHomeComponent
              datatestid="link_topbar_home"
              size={IconsSize.LARGE}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BLACK}
            />
          </LBTButtonIcon>
        </Stack>
      </Stack>
    </StyledStickyHeader>
  );
};

export default NavMenu;
