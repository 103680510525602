import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { navMenuState } from '../recoil/common';

export default function useScrollColor<T extends HTMLElement>(
  ref: React.RefObject<T>,
  changeBreakpoint: number,
  color?: string,
) {
  const setNavColor = useSetRecoilState(navMenuState);
  const metaTag = useRef(document.querySelector('meta[name="theme-color"]'));
  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const handleScrollEvent = () => {
      if (ref && ref.current) {
        // const scrollTop = window.scrollY;
        const startRefTop = ref.current.getBoundingClientRect().top;

        if (startRefTop <= changeBreakpoint && metaTag && metaTag.current) {
          metaTag.current.setAttribute('content', color + '');
          setNavColor(color + '');
        }
      }
    };

    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
